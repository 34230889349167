.navbar{
    height: 75px;
    width: 100%;
    top: 0;
    background-color: white;
    border-radius: 0;
    margin: 0;
    position: fixed;
    z-index: 999;
    box-shadow:         0px 1px 1px 0px rgba(50, 50, 50, 0.75);

    @media (max-width: 768px) {
        height: 80px;
    }

    .logo{
        @media (max-width: 768px) {
            text-align: center;
        }
        img{
            height: 65px;
            width: auto;
            margin-top: 5px;

            @media (max-width: 768px) {
                height: 40px;
            }
        }
    }

    nav{
        float: right;
        @media (max-width: 768px) {
            width: 100%;
        }
        ul{

            padding: 0;

            @media (max-width: 768px) {
                text-align: center;
            }
        
            li{
                display: inline-block;
                height: 75px;
                
                a{
                    line-height: 75px;
                    font-size: 16px;
                    font-weight: 600;
                    margin-left: 20px;
                    color: black;

                    @media (max-width: 768px) {
                        height: 40px;
                        line-height: 35px;
                        font-size: 14px;

                    }

                    @media (max-width: 440px) {
                        font-size: 12px;
                        margin: 0 5px;
                    }

                    @media (max-width: 440px) {
                        font-size: 11px;
                        margin: 0 3px;
                    }
                    
                }

                &.active{
                    a{
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}