.slider{
    position: relative;
    background-color: #000;
    max-height: $sliderHeight;
    overflow: hiden;

    .slick-list{
        width: 100%;
        height: $sliderHeight;
    }

    button{
        &.slick-arrow.slick-prev, &.slick-arrow.slick-next{
            position: absolute;
            top: 50%;
            z-index: 2;
            transform: scale(1.5) translateY(-50%);
        }

        &.slick-arrow.slick-prev{
            left: 20px;
        }
        &.slick-arrow.slick-next{
            right: 20px;
        }
    }

    .slick-slide{
        figure{
            position: relative;

            img{
                object-fit: cover;
                width: 100%;
                height: $sliderHeight;
                opacity: .75;
            }

            figcaption{
                position: absolute;
                z-index: 999;
                bottom: 15px;
                width: 100%;

                h1{
                    width: 100%;
                    margin: 30px;
                    text-align: left;
                    color: white;
                    text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                    margin: 0;
                    padding: 0 45px;
                    bottom: 0;
                    font-size: 32px;
                    line-height: 1;
                    
                }

                p{
                    font-size: 16px;
                    text-align: left;
                    color: white;
                    text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                    margin: 6px 0;
                    padding: 0 45px;
                }

                a.cta{
                    font-size: 18px;
                    color: white;
                    padding: 0 45px;
                }

                .author{
                    color: white;
                    position: absolute;
                    font-size: 12px;
                    bottom: 0;
                    right: 15px;
                    color: white;
                    text-shadow: 0px 0px 3px rgba(0,0,0,0.5);
                    opacity: .8;
                }
            }
        }
    }
    
}