.contactsSection{
    background-image: url("../images/contacts-bg.jpg");
    background-position: 0 -75%;
    background-size: cover;
    background-attachment: fixed;

    color: white;

    .sm-icon-link{
        float: left;
        margin-right: 10px;

        img{
            width: 40px;
            border: none;
            box-shadow: none;
        }
    }

    .icons-financiamento{
        padding: 0;
        margin: 0;
        width: 100%;
        text-align: center;

        li{
            display: inline-block;
            margin-right: 20px;
            height: 25px;

            p{
                font-size: 14px;
                line-height: 25px;
            }
            img{
                height: 25px;
                margin-bottom: 16px;
            }

            &:first-child{
                position: relative;
                top: -15px;
            }
        }
    }

    .form-control{
        margin-bottom: 32px;
        background-color: rgba(255,255,255,0.3);
        border-color: transparent;
        color: white;
    }
}