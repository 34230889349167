// responsive text align
.text-xs-left { text-align: left; }
.text-xs-right { text-align: right; }
.text-xs-center { text-align: center; }
.text-xs-justify { text-align: justify; }

@media (min-width: $screen-sm-min) {
    .text-sm-left { text-align: left; }
    .text-sm-right { text-align: right; }
    .text-sm-center { text-align: center; }
    .text-sm-justify { text-align: justify; }
}

@media (min-width: $screen-md-min) {
    .text-md-left { text-align: left; }
    .text-md-right { text-align: right; }
    .text-md-center { text-align: center; }
    .text-md-justify { text-align: justify; }
}

@media (min-width: $screen-lg-min) {
   .text-lg-left { text-align: left; }
   .text-lg-right { text-align: right; }
   .text-lg-center { text-align: center; }
   .text-lg-justify { text-align: justify; }
}

.margin-bottom{
    margin-bottom: 16px;
}
.margin-bottom-2x{
    margin-bottom: 32px;
}
.margin-top{
    margin-top: 16px;
}
.margin-top-2x{
    margin-top: 32px;
}

.no-margin{
    margin: 0;
}
.no-padding{
    padding: 0;
}

.reset-mb{
    margin-bottom: 0;
}


.thumbnail{
    .img-4-3{
        width: 100%;
        height: 0 !important;
        padding-bottom: 75%;
        background-position: center;
        background-size: cover;
    }
}

a.anchor {
    padding-top: 75px;
    margin-top: -75px;
    float: left;

    @media (max-width: 768px) {
        top: 80px;
    }
}

h1{
    font-size: 28px;
}

.fix-margin{
    margin-bottom: 16px;
}