.trip-heading-north{
    background-color: $brand-green;
    color: white;

    h1{
        margin: 0;
        font-size: 24px;
        padding: 10px 0;

    }

    h2{
        margin: 0;
        font-size: 18px;
        padding: 10px 0;
    }
}

.trip-heading-center{
    background-color: $brand-brown;
    color: white;

    h1{
        margin: 0;
        font-size: 24px;
        padding: 10px 0;
    }

    h2{
        margin: 0;
        font-size: 18px;
        padding: 10px 0;
    }
}

.trip-heading-lisbon{
    background-color: $brand-yellow;
    color: black;

    h1{
        margin: 0;
        font-size: 24px;
        padding: 10px 0;
    }

    h2{
        margin: 0;
        font-size: 18px;
        padding: 10px 0;
    }
}

.trip-heading-algarve{
    background-color: $brand-orange;
    color: white;

    h1{
        margin: 0;
        font-size: 24px;
        padding: 10px 0;
    }

    h2{
        margin: 0;
        font-size: 18px;
        padding: 10px 0;
    }
}

h2.price{

    margin-bottom: 20px;
    font-weight: bold;

    span:first-of-type{
        font-size: 16px;
        font-weight: 300;
    }
    span:last-of-type{
        font-size: 16px;
        display: block;
        font-weight: 300;
    }
}

h3{
    margin-top: 0;
}

.map-green{
    outline: solid $brand-green 3px;
}
.map-brown{
    outline: solid $brand-brown 3px;
}
.map-yellow{
    outline: solid $brand-yellow 3px;
}
.map-orange{
    outline: solid $brand-orange 3px;
}
.thumbnail {
    p{
        margin:5px 0;
        font-weight: bold;
    }
}
