.map{
    width: 100%;
    height: auto;
}

.map-legend{
    padding: 0 20px;
    width: 100%;
    text-align: center;

    li{
        display: inline-block;

        margin: 0 10px 10px 10px;

        img{
            width: 25px;
            height: auto;
            display: inline-block;
        }

        p{
            display: inline-block;
            line-height: 25px;
        }
    }
}